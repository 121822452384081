<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>{{translate('New API key')}}</v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-btn
        text
        :loading="user.loading"
        :disabled="!valid"
        @click="save"
        >
        <v-icon>save</v-icon> {{translate('Save')}}
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field
            :label="translate('API Key')"
            v-model="user.apiKey"
            :loading="user.loading"
            required
            :rules="validationRules.notEmpty()"
            />
          <v-text-field :label="translate('Description')" v-model="user.description" :enabled="false" :loading="user.loading"/>

          <v-checkbox v-for="role in roleNames" v-model="user.roles[role]" :label="role" :loading="user.loading" :key="role"/>

          <v-text-field :label="translate('Valid from')" type="date" v-model="user.valid.from" :loading="user.loading"/>
          <v-text-field :label="translate('Valid to')" type="date" v-model="user.valid.to" :loading="user.loading"/>
          <v-text-field
            :label="translate('CIDR')"
            v-model="user.valid.cidr"
            :rules="validationRules.cidr({optional: true})"
            pattern="((^|\.)((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]?\d))){4}$"
            :loading="user.loading"
            />
        </v-form>
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'

import zipObject from 'lodash/zipObject'

const randomBytes = require('randombytes')
import uuid from 'uuidv4'

export default {
  props: ['apiKey'],
  data: () => ({
    mutations: 1,
    valid: true,
    user: {
      apiKey: randomBytes(16).join('-'),
      roles: {},
      description: null,
      valid: {
        cidr: null,
        from: null,
        to: null
      }
    }
  }),
  components: {
    LayoutPage
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['api', 'translate', 'validationRules']),
    roleNames () {
      return Object.keys(this.user.roles)
    }
  },
  asyncComputed: {
    user: {
      async get () {
        let roles = await this.api.roles.getRoles()
        return {
          // id: randomBytes(16).join(''),
          apiKey: uuid().replace(/-/g, ''),
          roles: zipObject(roles, roles.map(() => false)),
          description: null,
          valid: {
            cidr: null,
            from: null,
            to: null
          }
        }
      },
      default: {
        loading: true,
        roles: {},
        valid: {}
      }
    }
  },
  methods: {
    async save () {
      await this.api.apiKeys.updateApiKeyUser(this.user, {query: {'create-only': 1}})

      this.$router.replace({
        name: 'edit-api-key',
        params: {
          apiKey: this.user.apiKey
        }
      })
    }
  }
}
</script>
